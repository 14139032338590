<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="
            modal-container
            md:w-10/12
            lg:w-6/12
            flex flex-col
            justify-center
            items-center
            font-proximaMedium
          "
        >
          <div class="modal-header flex flex-col justify-center items-center">
            <h3 class="text-xl mb-6">{{$t('modals_new_pet_title')}}</h3>
            <img
              rel="prefetch"
              src="@/assets/images/newpet-icon.png"
              height="100"
              width="100"
              alt="newpet-icon"
            />
          </div>
          <div
            slot="selectoption"
            class="
              w-full
              md:w-10/12
              flex
              justify-center
              mt-5
              mb-0
              font-proximaMedium
            "
          >
            <slot name="selectoption">
              <SelectOptions
                class="w-full px-0 md:w-6/12 mt-2 outline border"
                :dataList="petType"
                :title="$t('pet_record_overview_no_pets_dropdown_list')"
              />
            </slot>
          </div>
          <div
            class="
              modal-footer
              w-full
              flex flex-row
              justify-center
              items-center
            "
          >
            <slot name="leftBtn">
              <BorderedButton
                class="mr-1"
                :title="$t('global_cancel')"
                borderColor="border-mediumGrey"
                textCl="text-darkGrey"
                paddingX="px-8"
                width="w-11/12 md:w-4/12 lg:w-4/12"
                @click.native="$emit('close')"
              />
            </slot>
            <slot name="rightBtn">
              <SubmitButton
                class="ml-1"
                :title="$t('global_next')"
                background="bg-success"
                paddingX="px-8"
                width="w-11/12 md:w-4/12 lg:w-4/12"
              />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SelectOptions from "../forms/SelectOptions.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

export default {
  props: {
    show: Boolean,
  },
  components: {
    SelectOptions,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      petType: [
        { id: 1, name: "Dog" },
        { id: 2, name: "Cat" },
        { id: 3, name: "Ferret" },
        { id: 4, name: "Bird" },
        { id: 5, name: "Other" },
      ],
    };
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
