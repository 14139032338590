<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.5" cy="16.5" r="16.5" fill="#AAFFF9" />
    <path
      d="M33.6665 10.6665H36.9998C37.8839 10.6665 38.7317 11.0177 39.3569 11.6428C39.982 12.2679 40.3332 13.1158 40.3332 13.9998V37.3332C40.3332 38.2172 39.982 39.0651 39.3569 39.6902C38.7317 40.3153 37.8839 40.6665 36.9998 40.6665H16.9998C16.1158 40.6665 15.2679 40.3153 14.6428 39.6902C14.0177 39.0651 13.6665 38.2172 13.6665 37.3332V13.9998C13.6665 13.1158 14.0177 12.2679 14.6428 11.6428C15.2679 11.0177 16.1158 10.6665 16.9998 10.6665H20.3332"
      stroke="#059AA3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.0002 7.3335H22.0002C21.5581 7.3335 21.1342 7.50909 20.8217 7.82165C20.5091 8.13421 20.3335 8.55814 20.3335 9.00016V12.3335C20.3335 12.7755 20.5091 13.1994 20.8217 13.512C21.1342 13.8246 21.5581 14.0002 22.0002 14.0002H32.0002C32.4422 14.0002 32.8661 13.8246 33.1787 13.512C33.4912 13.1994 33.6668 12.7755 33.6668 12.3335V9.00016C33.6668 8.55814 33.4912 8.13421 33.1787 7.82165C32.8661 7.50909 32.4422 7.3335 32.0002 7.3335V7.3335Z"
      stroke="#059AA3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27 22.3335H33.6667"
      stroke="#059AA3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27 30.6665H33.6667"
      stroke="#059AA3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.3335 22.3335H20.3502"
      stroke="#059AA3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.3335 30.6665H20.3502"
      stroke="#059AA3"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 47,
    },
    height: {
      type: [Number, String],
      default: 44,
    },
  },
};
</script>