<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.5" cy="16.5" r="16.5" fill="#E2E7E6" />
    <g clip-path="url(#clip0_30737_2535)">
      <path
        d="M46.8708 21.1513L19.0027 10.0442C17.2926 9.36259 15.3537 10.1964 14.6721 11.9065L7.26738 30.4853C6.58579 32.1954 7.41959 34.1343 9.12972 34.8158L36.9978 45.923C38.708 46.6045 40.6468 45.7707 41.3284 44.0606L48.7332 25.4819C49.4147 23.7718 48.5809 21.8329 46.8708 21.1513Z"
        stroke="#C4C4C4"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2036 18.0991L46.2646 31.6745"
        stroke="#C4C4C4"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30737_2535">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(16.8262 2) rotate(21.7302)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 54,
    },
    height: {
      type: [Number, String],
      default: 54,
    },
  },
};
</script>