<template>
    <svg :width="width" :height="height" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3.5" cy="3.5" r="3.5" :fill="iconColor"/>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 7,
    },
    height: {
      type: [Number, String],
      default: 7,
    },
    iconColor: {
      type: String,
      default: "#C4C4C4",
    },
  },
};
</script>